<template>
  <div class="customer">
    <div class="search">
      <div class="search-input">
        <el-input
          prefix-icon="el-icon-search"
          placeholder="请输入公司名称"
          v-model="search.enterpriseName"
          @keyup.enter.native="searchFn"
        />
        <el-button class="hy-btn" @click="searchFn">查询</el-button>
        <el-button class="hy-btn" @click="addUser">添加客户信息</el-button>
      </div>
    </div>
    <div class="customer-table-list">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="customerName"
          label="客户公司名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="customerTelphone" label="账号" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="操作时间" align="center">
        </el-table-column>
        <el-table-column prop="" label="操作" width="200px" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="delCustomer(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="添加客户信息"
      :visible.sync="customerDiolog"
      width="900px"
      center
    >
      <div class="customer-input">
        <el-input
          v-model="diologForm.enterpriseName"
          placeholder="请输入客户公司名称"
          ><el-button slot="append" @click="searchCustomer"
            >查询</el-button
          ></el-input
        >
      </div>
      <div class="customer-transfer transfer">
        <el-transfer
          :titles="titles"
          ref="transfer"
          v-model="checkCustomer"
          :props="{
            key: 'userId',
            label: 'userName',
          }"
          :data="data"
          @change="checkChange"
        >
          <div slot-scope="{ option }">
            <span :title="option.userName">{{ option.userName }}</span
            >&nbsp; &nbsp;&nbsp; &nbsp;
            <span>{{ option.telephone }}</span>
          </div>
        </el-transfer>
        <div class="footer">
          <el-button @click="customerDiolog = false">取消</el-button>
          <el-button type="primary" @click="submitCustomer">确认添加</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAccountCustomers,
  addCustomer,
  deleteCustomer,
  getUnBindCustomerEnterprise,
} from "@/api/userCenters.js";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  name: "customer",
  data() {
    return {
      search: {
        enterpriseName: "",
      },
      tableData: [],
      customerDiolog: false,
      enterpriseId: "",
      userId: "",
      MainAccountCustomer: [],
      SubAccountCustomer: [],
      data: [],
      checkCustomer: [],
      titles: ["用户列表", "已选择用户"],
      diologForm: {
        enterpriseName: "",
      },
      keepAliveList: [],
    };
  },
  created() {
    let id = Vue.ls.get(types.enterpriseId);
    let userId = Vue.ls.get(types.userId);
    this.enterpriseId = id;
    this.userId = userId;
    this.searchFn();
  },
  methods: {
    // 穿梭框变化
    checkChange(e, type, list) {
      if (type === "right") {
        let currentList = [];
        list.forEach((item) => {
          currentList = [
            ...currentList,
            this.data.find((i) => i.userId === item),
          ];
        });
        this.keepAliveList = [...this.keepAliveList, ...currentList];
      } else {
        list.forEach((item) => {
          this.keepAliveList = this.keepAliveList.filter(
            (i) => i.userId !== item
          );
        });
      }
    },
    // 穿梭框
    searchCustomer() {
      getUnBindCustomerEnterprise({
        ...this.diologForm,
        ...{ enterpriseId: this.enterpriseId },
      }).then((res) => {
        if (res.code == 0) {
          let current = res.data?.concat(this.keepAliveList) || [];
          this.data = [...new Set(current.map(JSON.stringify))].map(JSON.parse);
          console.log(this.data);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    delCustomer(row) {
      if (!this.beforhandle("personalCenter:customerRelationship:delete")) return;
      deleteCustomer({ relationshipIdList: [row.relationshipId] }).then(
        (res) => {
          if (res.code == 0) {
            this.$message.success("删除成功");
            this.searchFn();
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    submitCustomer() {
      if (this.checkCustomer.length == 0) {
        this.$message.error("请选择客户");
        return;
      }
      addCustomer({
        customerIdList: this.checkCustomer,
        userId: this.enterpriseId,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success("添加成功");
          this.customerDiolog = false;
          this.searchFn();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    async getAccountCustomers(obj) {
      await getAccountCustomers({
        enterpriseId: this.enterpriseId,
        enterpriseName: obj ? obj.enterpriseName : "",
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data || [];
        }
      });
    },
    searchFn() {
      this.getList(this.search);
    },
    getList(obj) {
      this.getAccountCustomers(obj);
    },
    addUser() {
      if (!this.beforhandle("personalCenter:customerRelationship:add")) return;
      this.keepAliveList = [];
      this.checkCustomer = [];
      this.data = [];
      this.diologForm.enterpriseName = "";
      this.searchCustomer();
      this.customerDiolog = true;
    },
  },
};
</script>
<style lang="scss">
.customer-input {
  //   width: 300px;
}
.customer-transfer {
  .el-transfer {
    .el-transfer-panel {
      flex: 1;
      .el-transfer-panel__list {
        .el-checkbox__label {
          & > div {
            // display: flex;
            // align-items: center;
            span {
              display: inline-block;
              &:nth-child(1) {
                width: 120px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
      }
      //   &:nth-of-type(3) {
      //     * {
      //       color: #606266;
      //     }
      //     .el-transfer-panel__header {
      //       .el-checkbox__input {
      //         display: none;
      //       }
      //       .el-checkbox__label {
      //         padding-left: 0;
      //       }
      //       .el-checkbox__label {
      //         span {
      //           display: none;
      //         }
      //       }
      //     }
      //     .el-transfer-panel__body {
      //       .el-checkbox__input {
      //         display: none;
      //       }
      //       .el-checkbox__label {
      //         padding-left: 0;
      //       }
      //     }
      //   }
    }
  }
}
</style>
<style lang="scss" scoped>
.customer {
  margin: 14px auto;
  padding: 20px 40px;
  background: white;
  padding: 21px;
  border: 1px solid #e5e5e5;
  min-height: 500px;
  &::before,
  &::after {
    display: table;
    content: "";
  }
  .transfer {
    margin-top: 20px;
    &::v-deep {
      .el-transfer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .search-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 300px;
      margin-right: 10px;
    }
  }
  .customer-table-list {
    min-height: 500px;
    margin-top: 20px;
    .el-table {
      border: 1px solid #e5e5e5;
    }
    &::v-deep {
      thead th {
        background: #f7f7f7;
        .cell {
          font-weight: normal;
          color: #333;
        }
      }
    }
  }
}
</style>
